/* Services block */
.services-block {
  background: #f6f6f6;
  padding: 60px 0 70px;
}
.services-block .row {
  padding-top: 44px;
}
.services-block .item {
  border-right: solid 1px #e8e7e7;
  padding-top: 9px;
}
.services-block .col-md-3:last-child {
  border-right: none;
}
.services-block .fa {
  background: #e85425;
  color: #fff;
  width: 99px;
  height: 99px;
  line-height: 1;
  border-radius: 50%;
  position: relative;
  margin-bottom: 29px;
}
.services-block .fa:after {
  bottom: -8px;
  width: 0;
  height: 0;
  left: 50%;
  margin-left: -9px;
  z-index: 2;
  content: " ";
  display: block;
  position: absolute;
  border-top: 9px solid #e85425;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
}

.services-block .fa {
  font-size: 38px;
  padding: 35px 0 0;
}

.services-block p {
  margin-bottom: 5px;
}
