/* footer */
.footer {
  background: #272626;
  color: #fff;
  font-size: 12px;
  padding: 15px 0;
}
.footer-light {
  background: #f9f9f9;
  color: #3e4d5c;
}
.footer-gray {
  background: #4a5866;
}
.footer a {
  color: #fff;
  text-decoration: underline;
}
.footer a:hover {
  text-decoration: none;
}
.footer-light a {
  color: inherit;
}
.footer .padding-top-10 {
  opacity: 0.5;
}
.footer .list-inline > li:last-child {
  padding-right: 0;
}
.footer ul {
  margin: 0;
  padding: 0;
}
.footer ul.social-footer {
  font-size: 18px;
  padding-top: 5px;
}
.footer .social-icons li {
  float: right;
  margin: 0 0 0 5px;
}
.copyright {
  padding-top: 7px;
}
@-moz-document url-prefix() {
  .copyright {
    padding-top: 6px;
  }
}
.footer ul.social-icons li a {
  opacity: 0.5;
}
.footer ul.social-icons li:hover a {
  opacity: 1 !important;
}

