/* Choose us block */
.choose-us-block {
  padding-top: 60px;
}
.choose-us-block .row {
  padding-top: 44px;
}

.choose-us-block h4 {
  margin-bottom: 35px;
}
.choose-us-block h4 a {
  color: #1ea9e3;
}
.choose-us-block .panel-group {
  padding-top: 50px;
}
.choose-us-block .panel-default {
  border-radius: 0 !important;
  border: none;
  background: none;
  margin-bottom: 0 !important;
  padding-bottom: 14px;
}
.choose-us-block .panel-default > .panel-heading {
  border: none;
  background: none;
  padding: 0;
}
.choose-us-block .panel-default > .panel-heading + .panel-collapse .panel-body {
  border: none;
  padding: 0 0 0 32px;
}
.choose-us-block .panel-default h5 {
  font: 300 19px "Open Sans", Arial, sans-serif;
  padding: 0;
  margin: 0 0 5px;
}
.choose-us-block .panel-default .accordion-toggle:before {
  content: "";
  width: 21px;
  height: 21px;
  display: inline-block;
  /* background: #e54a1a url(../img/toggle-icons.png) no-repeat 6px 10px; */
  border-radius: 50% !important;
  margin-right: 10px;
  position: relative;
  top: 4px;
}
.choose-us-block .panel-default .collapsed:before {
  /* background: #495764 url(../img/toggle-icons.png) no-repeat 6px -148px; */
}
.choose-us-block .panel-default .panel-title:hover .collapsed:before {
  background-color: #e54a1a;
}
.choose-us-block .panel-default .accordion-toggle,
.choose-us-block .panel-default .accordion-toggle:focus,
.choose-us-block .panel-default .accordion-toggle:hover,
.choose-us-block .panel-default .accordion-toggle:active {
  color: #1ea9e3;
  text-decoration: none;
}
.choose-us-block .panel-default .collapsed {
  color: #5f6f7e;
}
