/* About- block */
.about-block {
  padding-top: 60px;
}
.about-block h4 {
  margin-bottom: 43px;
}
.ab-trio {
  padding-top: 105px;
  position: relative;
}
.ab-trio img {
  position: relative;
  z-index: 2;
}

.ab-cirlce {
  display: inline-block;
  width: 135px;
  height: 135px;
  color: #fff;
  border-radius: 50% !important;
  z-index: 1;
  position: absolute;
  padding-top: 30px;
}
.ab-cirle-blue {
  background: #33b1e6;
  bottom: 2px;
  left: 5%;
}
.ab-cirle-red {
  background: #e85425;
  bottom: 61%;
  left: 37%;
}
.ab-cirle-green {
  background: #94db40;
  bottom: 34%;
  right: 9%;
}
.ab-cirlce i {
  font-size: 43px;
  line-height: 30px;
}
.ab-cirlce strong {
  font: 400 18px/18px "PT Sans Narrow", Arial, sans-serif;
  display: block;
  padding-top: 12px;
}
