/* message block */
.message-block {
  min-height: 457px;
  height: auto;
  padding: 0 0 50px;
  z-index: 0;
  background: #292f34 url(./Prof_alumnos.jpg) no-repeat fixed;
  position: relative;
}
.message-block:after {
  background: rgba(0, 0, 0, 0.73);
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}

/* Estilos para Titulo de Instalaciones */
.InstalationTextCustom {
  font: 300 15px "Open Sans", Arial, sans-serif;
  z-index: 2;
  color: #fff;
  line-height: 42px;
  position: relative;
  letter-spacing: 1px;
  padding-top: 12%;
}

.InstalationTextCustom::after {
  z-index: 2;
  content: " ";
  display: block;
  padding-top: 7px;
  border-bottom: 2px solid #fafafa;
  width: 73px;
  margin: 0 auto;
  padding-top: 3%;
}

@media screen and (min-width: 500px) {
  .InstalationTextCustom {
    font-size: 60px !important;
    padding-top: 35%;
  }
}

@media screen and (min-width: 600px) {
  .InstalationTextCustom {
    font-size: 90px !important;
    padding-top: 25%;
  }
}

@media screen and (min-width: 900px) {
  .InstalationTextCustom {
    font-size: 130px !important;
    padding-top: 12%;
  }
}

/* Portfolio block */
.portfolio-block {
  background: #f6f6f6;
  padding-top: 60px;
}
.portfolio-block .row {
  padding: 0;
  margin: 0;
}
.portfolio-block .item {
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.portfolio-block .item > .valign-center-elem {
  background: rgba(229, 74, 26, 0.85);
  position: absolute;
  left: 0;
  bottom: -100%;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  pointer-events: none;

  transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;

  color: #fff;
}

.portfolio-block .item:hover > .valign-center-elem {
  bottom: 0;
}

.portfolio-block .item > .valign-center-elem:hover {
  text-decoration: none;
}

.ie8 .portfolio-block .item > a {
  background: #e54a1a;
}

.ie8 .portfolio-block .item .valign-center-elem {
  background: #e54a1a;
}

.portfolio-block .item strong {
  font: 400 17px/1.2 "Open Sans", Arial, sans-serif;
  display: block;
  margin-bottom: 4px;
}
.portfolio-block .item em {
  font: 300 13px "Open Sans", Arial, sans-serif;
  display: block;
  margin-bottom: 10px;
}
.portfolio-block .item b {
  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  border: solid 3px #fff;
  padding: 8px 15px 7px;
}

/*.portfolio-block .item b:hover {*/
.portfolio-block .item b {
  background: #fff;
  color: #e85425;
  border-color: #fff;
  cursor: pointer;
}

.modal-image {
  cursor: pointer;

  height: 180px;
}
