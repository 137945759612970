.my-modal .modal-content {
  background: #292f34 url(Fondo_cielo.jpg) no-repeat;
  background-color: white;
  background-size: cover;
  color: white;
}

.my-modal .img-fluid {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.leyesHover:hover {
  color: red;
}

.leyesHover > img:hover {
  background-color: yellow;
  color: red;
}
