/* go2top */
.scrollTop,
.scrollTop:focus {
  position: fixed;
  right: 15px;
  bottom: 8px;

  color: #999;
  width: 38px;
  height: 38px;

  border: solid 2px #999;
  border-radius: 50% !important;
  text-align: center;
  padding: 7px 0 0 1px;
  z-index: 99999;

  font-size: 18px;
  line-height: 1;
}
.scrollTop:hover {
  border: solid 2px #e6400c;
  color: #e6400c;
}

.scrollTop i {
  font-size: 18px;
  line-height: 18px;
}

/*
.scrollTop {
  position: fixed;
  width: 100%;
  bottom: 20px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}
*/
/*
.scrollTop:hover {
  opacity: 1;
}
*/

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
