/**********************************************/

/* General body settings */
body {
  color: #5f6f7e;
  direction: ltr;
  font: 300 14px/1.4 "Open Sans", Arial, sans-serif;
  background: #fff;
}

/* h1-h6 */
.content h1,
.content h2 {
  font: 400 37px "PT Sans Narrow", Arial, sans-serif;
  margin: 0 0 10px;
  text-transform: uppercase;
  text-align: center;
}
.content h1:after,
.content h2:after {
  z-index: 2;
  content: " ";
  display: block;
  padding-top: 7px;
  border-bottom: 2px solid #b7bbbf;
  width: 73px;
  margin: 0 auto;
}
.content h3,
.content h4,
.content h5,
.content h6 {
  font: 300 18px "Open Sans", Arial, sans-serif;
  padding: 0;
  margin: 0;
}
.content h1 strong,
.content h2 strong,
.content h3 strong,
.content h4 strong {
  color: #e54a1a;
  font-weight: 300;
}
.content h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
}
.content h4 {
  font-size: 18px;
}
.content h5 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 5px;
  padding-top: 5px;
}
.content h6 {
  font-size: 15px;
  line-height: 1.4;
  font-weight: 700;
  margin-bottom: 5px;
  padding-top: 5px;
}
.h-gray {
  opacity: 0.7;
}

/* links styles */
a,
a:focus,
a:hover,
a:active {
  color: #e6400c;
  outline: 0;
  text-decoration: none;
}
/*
a:hover {
  color: #e6400c;
  text-decoration: underline;
}
*/
/* Global classes */
::-moz-selection {
  color: #fff;
  background: #e6400c;
}
::selection {
  color: #fff;
  background: #e6400c;
}

.min-hight500 {
  min-height: 500px;
}

.lead {
  font: 400 17px "Open Sans", sans-serif;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
}
*html .clearfix {
  zoom: 1;
}
* + html .clearfix {
  zoom: 1;
}
.clear {
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.content-center {
  text-align: center;
}
.border-bottom-silver {
  border-bottom: solid 1px #b7bbbf;
}

.more-li-space li {
  margin-bottom: 3px;
}

.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-bottom-padding {
  padding-bottom: 0 !important;
}
.no-bottom-space {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.no-top-padding {
  padding-top: 0 !important;
}
.no-top-space {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.no-space {
  margin: 0 !important;
  padding: 0 !important;
}
.no-text-shadow {
  text-shadow: none;
}

.padding-top-5 {
  padding-top: 5px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-top-20 {
  padding-top: 20px !important;
}
.padding-top-25 {
  padding-top: 25px !important;
}
.padding-top-30 {
  padding-top: 30px !important;
}
.padding-top-35 {
  padding-top: 35px !important;
}
.padding-top-40 {
  padding-top: 40px !important;
}
.padding-top-45 {
  padding-top: 45px !important;
}
.padding-top-50 {
  padding-top: 50px !important;
}
.padding-top-60 {
  padding-top: 60px !important;
}
.padding-top-70 {
  padding-top: 70px !important;
}
.padding-top-80 {
  padding-top: 80px !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-bottom-25 {
  margin-bottom: 25px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.margin-bottom-35 {
  margin-bottom: 35px !important;
}
.margin-bottom-40 {
  margin-bottom: 40px !important;
}
.margin-bottom-45 {
  margin-bottom: 45px !important;
}
.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.margin-bottom-60 {
  margin-bottom: 60px !important;
}
.margin-bottom-65 {
  margin-bottom: 65px !important;
}
.margin-bottom-70 {
  margin-bottom: 70px !important;
}
.margin-bottom-80 {
  margin-bottom: 80px !important;
}
.margin-bottom-90 {
  margin-bottom: 90px !important;
}
.margin-bottom-100 {
  margin-bottom: 100px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-left-0 {
  margin-left: 0 !important;
}
.margin-right-0 {
  margin-right: 0 !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}
.padding-right-0 {
  padding-right: 0 !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.btn {
  padding: 7px 14px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 0;
}
.btn-primary {
  border: none;
  background: #e94d1c;
  color: #fff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  color: #fff;
  background: #cc3304;
  border: none;
}
.content-page .btn-primary,
.content-page .btn-primary:hover {
  color: #fff;
  margin-left: 0 !important;
}
.btn-default {
  border: 1px solid #ededed;
  color: #a8aeb3;
  background: transparent;
  padding: 6px 13px;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  border: 1px solid #a8aeb3;
  color: #fff;
  background: #a8aeb3;
  padding: 6px 13px;
}

/*  media */
.embed-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* panels style */
/*.panel-default {
  border-color: #ecf0f1;
}
.panel-default > .panel-heading {
  background: #ecf0f1;
  border-color: #ecf0f1;
  color: #333;  
}
.panel-default > .panel-heading h4 {
  font: 300 16px "Open Sans", sans-serif;
  padding: 0;
  margin-bottom: 0;
}
.panel-default > .panel-heading a {
  text-decoration: none;
  color: inherit;
}
.panel-default > .panel-heading a:active,
.panel-default > .panel-heading a:focus {
  color: inherit;
}*/

/* Team block */
.team-block {
  padding-top: 60px;
}
.team-block h4 {
  margin-bottom: 45px;
}
.team-block .item {
  margin-bottom: 30px;
}
.team-block h3 {
  font-size: 20px;
  padding-top: 13px;
  margin-bottom: 2px;
}
.team-block em {
  color: #e54a1a;
  text-transform: uppercase;
  display: block;
  font: 400 14px "Open Sans", Arial, sans-serif;
  margin-bottom: 12px;
}
.tb-socio {
  padding-top: 3px;
  text-align: center;
}
.tb-socio .fa {
  width: 33px;
  height: 33px;
  color: #b3bdc6;
  border-radius: 50% !important;
  border: solid 1px #d5d5d5;
  font-size: 19px;
  line-height: 19px;
  margin: 0 7px;
  padding-top: 7px;
}
.tb-socio .fa:hover {
  color: #e6400c;
  border: solid 1px #e6400c;
  text-decoration: none;
}

/* Partners block */
.partners-block {
  background: #f6f6f6;
  padding: 17px 0;
  text-align: center;
}

/* Facts block */
.facts-block {
  position: relative;
  min-height: 470px;
  height: auto;
  padding: 57px 0 0;
  z-index: 0;
  /* background: #fff url(../img/facts.jpg) no-repeat fixed; */
  position: relative;
  color: #fff;
}
.facts-block h2 {
  background: rgba(0, 0, 0, 0.29);
  line-height: 37px;
  padding: 14px 0 15px;
  margin-bottom: 71px;
}
.facts-block h2:after {
  display: none;
}
.facts-block .item {
  background: rgba(255, 99, 77, 0.92);
  font: 400 20px "Pathway Gothic One", Arial, sans-serif;
  text-transform: uppercase;
  width: 198px;
  height: 198px;
  border-radius: 50% !important;
  padding: 33px 0 0;
  margin: 0 auto;
}
@-moz-document url-prefix() {
  .facts-block .item {
    padding: 31px 0 0;
  }
}
.ie8 .facts-block .item {
  background: #e54a1a;
}
.facts-block .item strong {
  display: block;
  font-size: 80px;
  text-transform: none;
  margin-bottom: 4px;
}

/* Checkout block */
.checkout-block {
  background: #292f34;
  color: #c8ced4;
  padding: 25px 0;
}
.checkout-block h2 {
  font: 400 33px "PT Sans Narrow", Arial, sans-serif;
  margin: 2px 0 0;
  text-align: left;
}
.checkout-block h2 em {
  color: #767c81;
  font: 300 18px "Open Sans", Arial, sans-serif;
  text-transform: none;
}
.checkout-block h2:after {
  display: none;
}
.checkout-block .btn {
  font: 400 20px/20px "PT Sans Narrow", Arial, sans-serif;
  padding: 15px 20px 14px;
}
@-moz-document url-prefix() {
  .checkout-block .btn {
    padding: 14px 20px 15px;
  }
}

/***
Styler Panel
***/
.color-panel {
  z-index: 99999;
  position: fixed;
  top: 120px;
  right: 0;
}

.color-panel .menu-pos {
  margin: 0 0 15px 15px;
  width: 150px;
}

.color-panel .color-mode-icons {
  top: 0;
  right: 0;
  padding: 20px;
  cursor: pointer;
  position: absolute;
  margin: 0;
}
.color-panel .icon-color {
  /* background: #c9c9c9 url(./img/icon-color.png) center no-repeat; */
}
.color-panel .icon-color:hover {
  background-color: #3d3d3d;
}
.color-panel .icon-color-close {
  display: none;
  /* background: #3d3d3d url(./img/icon-color-close.png) center no-repeat; */
}
.color-panel .icon-color-close:hover {
  background-color: #222;
}
.color-mode {
  top: 0;
  right: 40px;
  width: 200px;
  display: none;
  padding: 20px 10px 10px;
  position: absolute;
  background: #3d3d3d;
}
.color-mode p {
  color: #cfcfcf;
  padding: 0 15px;
  font-size: 15px;
}
.color-mode ul {
  list-style: none;
  padding: 4px 11px 5px;
  display: block;
  margin-bottom: 1px !important;
}
.color-mode li {
  width: 28px;
  height: 28px;
  margin: 4px;
  cursor: pointer;
  list-style: none;
  border: solid 1px #707070;
  display: inline-block;
  padding: 0 5px;
}
.color-mode li:hover,
.color-mode li.current {
  border: solid 1px #ebebeb;
}
.color-mode li.current {
  border: solid 2px #ebebeb;
}

.color-mode li.color-blue {
  background: #64aed9;
}
.color-mode li.color-red {
  background: #e84d1c;
}
.color-mode li.color-green {
  background: #67bd3c;
}
.color-mode li.color-orange {
  background: #e18604;
}
.color-gray {
  background: #798b97;
}
.color-turquoise {
  background: #44b1c1;
}
.color-mode label {
  color: #cfcfcf;
  border-top: 1px solid #585858;
  padding: 10px 10px 0;
  margin: 0 5px;
  display: block;
}
.color-mode label span {
  text-transform: uppercase;
}
.color-mode label > span {
  display: inline-block;
  width: 85px;
}
.color-mode label > select {
  margin-top: 5px;
  text-transform: lowercase;
}
.color-mode label span.color-mode-label {
  top: 2px;
  position: relative;
}

/*********************************************************************/
/*********************************************************************/

.header-navigation {
  font-size: 15px;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.fancybox-overlay {
  z-index: 100000;
}

.fancybox-opened {
  z-index: 100001;
}

/* Clases Para el Manejo  de los estilos tipo Botones */

.ab-trio {
  margin-top: 135px;
}

.ab-trio img {
  position: relative;
  z-index: 2;
}

.ab-cirlce {
  display: inline-block;
  width: 135px;
  height: 135px;
  color: #fff;
  border-radius: 50% !important;
  z-index: 1;
  position: absolute;
  padding-top: 30px;
}
.ab-cirle-blue {
  background: #33b1e6;
  bottom: 70px;
  left: 5%;
}
.ab-cirle-red {
  background: #e85425;
  bottom: -80%;
  left: 5%;
}
.ab-cirle-green {
  background: #94db40;
  bottom: 34%;
  right: 9%;
}
.ab-cirlce i {
  font-size: 43px;
  line-height: 30px;
}
.ab-cirlce strong {
  font: 400 18px/18px "PT Sans Narrow", Arial, sans-serif;
  display: block;
  padding-top: 12px;
}

/* Estilos Usados en los Botones de las Leyes y los Decretos */

/* map */
#map {
  display: block;
  height: 486px;
  width: 100%;
  background-color: #f6f4ef;
}
#map img {
  max-width: inherit !important;
}
#map .map-error {
  text-align: center;
  padding-top: 48px;
  color: #c0b491;
  font-size: 14px;
}
#map .fa {
  font-size: 60px;
  color: #1f1f1f;
}

/* Estilos de las Leyes */

.leyesEscudoShape {
  background-color: #c2c914;
  border-radius: 50%;
  box-shadow: 5px 1px 3px #000;
}
