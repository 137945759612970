/* Testimonials block */
.testimonials-block {
  padding-top: 60px;
}
.testimonials-block h4 {
  margin-bottom: 57px;
}
.testimonials-block .carousel {
  width: 62%;
  margin: 0 auto;
}
.testimonials-block blockquote {
  border: none;
  font: italic 300 23px/1.3 "Open Sans", Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #66717c;
}
.testimonials-name {
  font: italic 300 23px/1.3 "Open Sans", Arial, sans-serif;
  color: #2dafe5;
}
.carousel-indicators {
  position: static;
  margin: 27px 0 0;
  width: 100%;
}
.carousel-indicators li {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  background: #dce0e2;
  position: relative;
  margin: 0 3px !important;
  border: none;
}
.carousel-indicators li.active {
  background: #e6400c;
}
