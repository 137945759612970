/* Header and header elements */
.header {
  /*  position: absolute;
  top: auto; */
  bottom: 0;
  left: auto;
  right: auto;
  z-index: 9;
  width: 100%;
  min-height: 60px;
  padding: 0;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 1px 4px rgba(228, 228, 228, 0.95);
  z-index: 999;
}

.header.fixNav {
  position: fixed;
  top: 0;
  bottom: auto;
  background: rgba(255, 255, 255, 0.95);
}

.header .mobi-toggler {
  float: right;
  color: #d8d8d8;
  border: 1px solid #d8d8d8;
  border-radius: 100% !important;
  width: 32px;
  height: 32px;
  display: none;
  margin: 15px 15px 0;
  position: relative;
  background: url(toggler.png) no-repeat 6px 8px;
}

.header .mobi-toggler i {
  display: none;
}

.header .mobi-toggler:hover {
  background: #e34f00 url(toggler.png) no-repeat 6px -28px;
  border-color: #e34f00;
  color: #fff;
}

.menu-always-on-top .header {
  background: #fff;
  top: 0;
  bottom: auto;
  position: fixed;
}

.ie8 .header {
  background: #fff !important;
}
.ie8 .header.fixNav {
  border-bottom: solid 1px #ddd;
}

/* header at the top */
.header-top {
  top: 0;
  bottom: auto;
  background: rgba(255, 255, 255, 1);
}
/* header on the second screen */
.header-second-screen {
  bottom: -63px; /* 61px – header height */
}
.header.fixNav {
  position: fixed;
  top: 0;
  bottom: auto;
  background: rgba(255, 255, 255, 0.95);
}

.site-logo {
  float: left;
  padding-top: 19px;
}

.header-navigation {
  float: right;
  list-style: none;
  padding: 19px 0 0;
  margin: 0;
  font: 400 18px "PT Sans Narrow", Arial, sans-serif;
  text-transform: uppercase;
}
.header-navigation li {
  float: left;
  padding: 0;
  margin: 0 0 0 25px;
}
.header-navigation a {
  display: block;
  color: #78828c;
}
.header-navigation a:hover,
.header-navigation li.current a {
  color: #e6400c;
}

.header a:hover {
  text-decoration: none;
}

.header-navigation a:hover,
.header-navigation li.current a {
  color: #e6400c;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

a {
  color: #a5a5a5;
  outline: 0;
  text-decoration: none;
}

.active {
  color: #e6400c !important;
  font-weight: 600;
}
