
/***
Social Icons
***/
.social-icons {
	padding: 0;
	margin: 0;
  }
  .social-icons:before,
  .social-icons:after {
	content: " ";
	display: table;
  }
  .social-icons:after {
	clear: both;
  }
  .social-icons li {
	float: left;
	display: inline;
	list-style: none;
	margin-right: 5px;
	margin-bottom: 5px;
	text-indent: -9999px;
  }
  .social-icons li > a {
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
	border-radius: 2px;
	width: 28px;
	height: 28px;
	display: block;
	background-position: 0 0;
	background-repeat: no-repeat;
	transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
  }
  .social-icons li:hover > a {
	background-position: 0 -38px;
  }
  .social-icons li .amazon {
	background: url(../main-page/img/social/amazon.png) no-repeat;
  }
  .social-icons li .behance {
	background: url(../main-page/img/social/behance.png) no-repeat;
  }
  .social-icons li .blogger {
	background: url(../main-page/img/social/blogger.png) no-repeat;
  }
  .social-icons li .deviantart {
	background: url(../main-page/img/social/deviantart.png) no-repeat;
  }
  .social-icons li .dribbble {
	background: url(../main-page/img/social/dribbble.png) no-repeat;
  }
  .social-icons li .dropbox {
	background: url(../main-page/img/social/dropbox.png) no-repeat;
  }
  .social-icons li .evernote {
	background: url(../main-page/img/social/evernote.png) no-repeat;
  }
  .social-icons li .facebook {
	background: url(../main-page/img/social/facebook.png) no-repeat;
  }
  .social-icons li .forrst {
	background: url(../main-page/img/social/forrst.png) no-repeat;
  }
  .social-icons li .github {
	background: url(../main-page/img/social/github.png) no-repeat;
  }
  .social-icons li .googleplus {
	background: url(../main-page/img/social/googleplus.png) no-repeat;
  }
  .social-icons li .jolicloud {
	background: url(../main-page/img/social/jolicloud.png) no-repeat;
  }
  .social-icons li .last-fm {
	background: url(../main-page/img/social/last-fm.png) no-repeat;
  }
  .social-icons li .linkedin {
	background: url(../main-page/img/social/linkedin.png) no-repeat;
  }
  .social-icons li .picasa {
	background: url(../main-page/img/social/picasa.png) no-repeat;
  }
  .social-icons li .pintrest {
	background: url(../main-page/img/social/pintrest.png) no-repeat;
  }
  .social-icons li .rss {
	background: url(../main-page/img/social/rss.png) no-repeat;
  }
  .social-icons li .skype {
	background: url(../main-page/img/social/skype.png) no-repeat;
  }
  .social-icons li .spotify {
	background: url(../main-page/img/social/spotify.png) no-repeat;
  }
  .social-icons li .stumbleupon {
	background: url(../main-page/img/social/stumbleupon.png) no-repeat;
  }
  .social-icons li .tumblr {
	background: url(../main-page/img/social/tumblr.png) no-repeat;
  }
  .social-icons li .twitter {
	background: url(../main-page/img/social/twitter.png) no-repeat;
  }
  .social-icons li .vimeo {
	background: url(../main-page/img/social/vimeo.png) no-repeat;
  }
  .social-icons li .wordpress {
	background: url(../main-page/img/social/wordpress.png) no-repeat;
  }
  .social-icons li .xing {
	background: url(../main-page/img/social/xing.png) no-repeat;
  }
  .social-icons li .yahoo {
	background: url(../main-page/img/social/yahoo.png) no-repeat;
  }
  .social-icons li .youtube {
	background: url(../main-page/img/social/youtube.png) no-repeat;
  }
  .social-icons li .vk {
	background: url(../main-page/img/social/vk.png) no-repeat;
  }
  .social-icons li .instagram {
	background: url(../main-page/img/social/instagram.png) no-repeat;
  }
  .social-icons li .reddit {
	background: url(../main-page/img/social/reddit.png) no-repeat;
  }
  .social-icons li .aboutme {
	background: url(../main-page/img/social/aboutme.png) no-repeat;
  }
  .social-icons li .flickr {
	background: url(../main-page/img/social/flickr.png) no-repeat;
  }
  .social-icons li .foursquare {
	background: url(../main-page/img/social/foursquare.png) no-repeat;
  }
  .social-icons li .gravatar {
	background: url(../main-page/img/social/gravatar.png) no-repeat;
  }
  .social-icons li .klout {
	background: url(../main-page/img/social/klout.png) no-repeat;
  }
  .social-icons li .myspace {
	background: url(../main-page/img/social/myspace.png) no-repeat;
  }
  .social-icons li .quora {
	background: url(../main-page/img/social/quora.png) no-repeat;
  }
  .social-icons.social-icons-color > li > a {
	opacity: 0.7;
	background-position: 0 -38px !important;
  }
  .social-icons.social-icons-color > li > a:hover {
	opacity: 1;
  }
  .social-icons.social-icons-circle > li > a {
	border-radius: 25px !important;
  }
  
  /***
  Inline Social Icons
  ***/
  .social-icon {
	display: inline-block !important;
	width: 28px;
	height: 28px;
	background-position: 0 0;
	background-repeat: no-repeat;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
	border-radius: 2px;
	transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
  }
  .social-icon.social-icon-circle {
	border-radius: 25px !important;
  }
  
  .social-icon.amazon {
	background: url(../main-page/img/social/amazon.png) no-repeat;
  }
  .social-icon.behance {
	background: url(../main-page/img/social/behance.png) no-repeat;
  }
  .social-icon.blogger {
	background: url(../main-page/img/social/blogger.png) no-repeat;
  }
  .social-icon.deviantart {
	background: url(../main-page/img/social/deviantart.png) no-repeat;
  }
  .social-icon.dribbble {
	background: url(../main-page/img/social/dribbble.png) no-repeat;
  }
  .social-icon.dropbox {
	background: url(../main-page/img/social/dropbox.png) no-repeat;
  }
  .social-icon.evernote {
	background: url(../main-page/img/social/evernote.png) no-repeat;
  }
  .social-icon.facebook {
	background: url(../main-page/img/social/facebook.png) no-repeat;
  }
  .social-icon.forrst {
	background: url(../main-page/img/social/forrst.png) no-repeat;
  }
  .social-icon.github {
	background: url(../main-page/img/social/github.png) no-repeat;
  }
  .social-icon.googleplus {
	background: url(../main-page/img/social/googleplus.png) no-repeat;
  }
  .social-icon.jolicloud {
	background: url(../main-page/img/social/jolicloud.png) no-repeat;
  }
  .social-icon.last-fm {
	background: url(../main-page/img/social/last-fm.png) no-repeat;
  }
  .social-icon.linkedin {
	background: url(../main-page/img/social/linkedin.png) no-repeat;
  }
  .social-icon.picasa {
	background: url(../main-page/img/social/picasa.png) no-repeat;
  }
  .social-icon.pintrest {
	background: url(../main-page/img/social/pintrest.png) no-repeat;
  }
  .social-icon.rss {
	background: url(../main-page/img/social/rss.png) no-repeat;
  }
  .social-icon.skype {
	background: url(../main-page/img/social/skype.png) no-repeat;
  }
  .social-icon.spotify {
	background: url(../main-page/img/social/spotify.png) no-repeat;
  }
  .social-icon.stumbleupon {
	background: url(../main-page/img/social/stumbleupon.png) no-repeat;
  }
  .social-icon.tumblr {
	background: url(../main-page/img/social/tumblr.png) no-repeat;
  }
  .social-icon.twitter {
	background: url(../main-page/img/social/twitter.png) no-repeat;
  }
  .social-icon.vimeo {
	background: url(../main-page/img/social/vimeo.png) no-repeat;
  }
  .social-icon.wordpress {
	background: url(../main-page/img/social/wordpress.png) no-repeat;
  }
  .social-icon.xing {
	background: url(../main-page/img/social/xing.png) no-repeat;
  }
  .social-icon.yahoo {
	background: url(../main-page/img/social/yahoo.png) no-repeat;
  }
  .social-icon.youtube {
	background: url(../main-page/img/social/youtube.png) no-repeat;
  }
  .social-icon.vk {
	background: url(../main-page/img/social/vk.png) no-repeat;
  }
  .social-icon.instagram {
	background: url(../main-page/img/social/instagram.png) no-repeat;
  }
  .social-icon.reddit {
	background: url(../main-page/img/social/reddit.png) no-repeat;
  }
  .social-icon.aboutme {
	background: url(../main-page/img/social/aboutme.png) no-repeat;
  }
  .social-icon.flickr {
	background: url(../main-page/img/social/flickr.png) no-repeat;
  }
  .social-icon.foursquare {
	background: url(../main-page/img/social/foursquare.png) no-repeat;
  }
  .social-icon.gravatar {
	background: url(../main-page/img/social/gravatar.png) no-repeat;
  }
  .social-icon.klout {
	background: url(../main-page/img/social/klout.png) no-repeat;
  }
  .social-icon.myspace {
	background: url(../main-page/img/social/myspace.png) no-repeat;
  }
  .social-icon.quora {
	background: url(../main-page/img/social/quora.png) no-repeat;
  }
  .social-icon:hover {
	background-position: 0 -38px;
  }
  
  .social-icon-color {
	opacity: 0.7;
	background-position: 0 -38px !important;
  }
  .social-icon-color:hover {
	opacity: 1;
  }
  