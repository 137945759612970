/* Promo block */
.promo-block {
  overflow-x: hidden;
}

/*
.slide {
  background-size:cover !important;
}
*/
.slider {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slider h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  font-size: 35px;
  line-height: 1;
  color: white;
}

.slider p {
  padding-top: 30px;
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  font-size: 15px;
  line-height: 1;
  color: darkgoldenrod;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(29, 25, 87, 0.4));
  bottom: 0;
  left: 0;
}
